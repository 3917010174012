import { ActionEventAction } from 'wikr-core-analytics';

import appConfig from 'appConfig';

import { PRIVATE } from 'router/constants/routes';

import { getLanguage } from 'api/utils/languageManagment';

import { useGetCFLink } from 'hooks';

import { getZendeskLink } from 'helpers/getZendeskLink';

import openBadge from './img/open.png';

export const getPrimarySidebarLinks = ({ isShowGuidesNotification }: { isShowGuidesNotification: boolean }) => {
    return [
        {
            to: PRIVATE.MAIN.path,
            title: 'sidebar.app',
            dataLocator: 'burgerFitcoachApp',
            analyticActionEvent: ActionEventAction.app,
        },
        {
            to: PRIVATE.FITNESS_GUIDES.path,
            title: 'sidebar.guides',
            dataLocator: 'burgerGuides',
            isPurchasedAdditionalProduct: <boolean>isShowGuidesNotification,
            analyticActionEvent: ActionEventAction.guides,
        },
        {
            to: PRIVATE.ACCOUNT_PROFILE.path,
            title: 'sidebar.settings',
            dataLocator: 'burgerAccountSettings',
            analyticActionEvent: ActionEventAction.accountSettings,
        },
    ];
};

export const useGetSecondarySidebarLinks = () => {
    const language = getLanguage();
    const cfLink = useGetCFLink();

    return [
        {
            to: getZendeskLink(),
            title: 'sidebar.faq',
            dataLocator: 'burgerFAQ',
            analyticActionEvent: ActionEventAction.faq,
            target: '_blank',
            external: true,
            badge: openBadge,
        },
        {
            to: cfLink,
            title: 'basics.contactUs',
            dataLocator: 'contactUsLink',
            analyticActionEvent: ActionEventAction.contactUs,
            target: '_blank',
            rel: 'noopener noreferrer',
            external: true,
            badge: openBadge,
        },
        {
            to: `${appConfig.links.termsOfUse}?language=${language}`,
            title: 'sidebar.terms',
            dataLocator: 'burgerTermsOfUse',
            target: '_blank',
            rel: 'noopener noreferrer',
            external: true,
            analyticActionEvent: ActionEventAction.termsOfUse,
        },
        {
            to: `${appConfig.links.privacyPolicy}?language=${language}`,
            title: 'sidebar.privacy',
            dataLocator: 'burgerPrivacyPolicy',
            target: '_blank',
            rel: 'noopener noreferrer',
            external: true,
            analyticActionEvent: ActionEventAction.privacyPolicy,
        },
        {
            to: `${appConfig.links.refundPolicy}?language=${language}`,
            title: 'sidebar.refund',
            dataLocator: 'burgerRefundPolicy',
            target: '_blank',
            rel: 'noopener noreferrer',
            external: true,
            analyticActionEvent: ActionEventAction.refundPolicy,
        },
    ];
};
