import { useTranslation } from 'react-i18next';
import React from 'react';

import OutlinedButton from 'components/OutlinedButton';
import { Box, Text } from 'components/library';
import { Alert } from 'components/Icon';

import * as S from './styled';

import { ISubscriptionRedemption } from '../types';

import SubscriptionCardWrapper from './SubscriptionCardWrapper';
import SubscriptionCardHeader from './SabscriptionCardHeader';
import PriceRow from './PriceRow';

const SubscriptionRedemption = ({
    tabIcon,
    heading,
    subscriptionPeriod,
    price,
    onCancelSubscription,
    subscriptionType,
}: ISubscriptionRedemption) => {
    const { t } = useTranslation();

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader icon={tabIcon} heading={heading} />
            <S.GeneralInfo>
                <S.Status>
                    <Alert />
                    <Text type="caption" color="text-secondary" text={t('subscription.status.active')} />
                    <Text type="caption" color="text-secondary" text=":" />
                </S.Status>
                <PriceRow period={subscriptionPeriod} subscriptionType={subscriptionType} price={price} />
            </S.GeneralInfo>
            <Text
                type="medium-text"
                bold
                color="critical-default"
                mb={4}
                text={t('subscription.text.awaitingPayment')}
            />
            <Text type="small-text" color="text-secondary" text={t('subscription.text.payYourBalance')} />
            <Box mb={16} />
            <OutlinedButton
                data-locator="cancelSubscriptionButton"
                onClick={onCancelSubscription}
                text={t('subscription.text.cancel')}
            />
        </SubscriptionCardWrapper>
    );
};

export default SubscriptionRedemption;
