import styled from 'styled-components';

import { Box, Image, Text } from 'components/library';

import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';
import themeConfig from '../../themeConfig';

export const Container = styled.div`
    width: 100%;
    margin: auto;
    max-width: 1028px;
    padding: 31px 16px 70px;

    ${mediaQueries.desktopSm} {
        padding-top: 48px;
    }

    ${mediaQueries.laptop} {
        padding-bottom: 0;
    }
`;

export const FlexWrapper = styled(Box)`
    display: flex;
`;

export const TabletWrapper = styled(Box)`
    display: none;
    flex-direction: column;

    ${mediaQueries.laptop} {
        display: block;
    }
`;

export const MobileWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 12px;

    ${mediaQueries.laptop} {
        display: none;
    }
`;

export const StoreWrapper = styled(Box)`
    display: flex;
    width: 432px;
    margin-top: 6px;

    div {
        margin-right: 12px;
    }
`;

export const StepContainer = styled(FlexWrapper)`
    width: 100%;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background: ${themeConfig.theme['surface-default']};
    padding: 20px;
    margin-top: 32px;

    ${mediaQueries.tablet} {
        padding: 48px;
    }

    ${mediaQueries.tabletLandscape} {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
    }
`;

export const DownloadWrapper = styled(Box)`
    width: 389px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    ${mediaQueries.tabletLandscape} {
        margin: 0 0 0 auto;
    }
`;

export const BottomBanner = styled.div`
    width: 303px;
    height: 72px;
    border-radius: 24px;
    background: ${color('success-highlight')};
    display: flex;
    padding: 24px;
    margin: 0 auto;

    svg {
        width: 24px;
        height: 24px;
        margin-right: 12px;
    }

    ${mediaQueries.tabletLandscape} {
        margin-left: 24px;
    }
`;

export const ArrowImg = styled(Image)`
    position: absolute;
    width: 150px;
    top: 95px;
    left: -80px;

    ${mediaQueries.tabletLandscape} {
        left: -93px;
    }
`;

export const StepSection = styled.div`
    width: 315px;

    ${mediaQueries.tabletLandscape} {
        width: 502px;
    }
`;

export const DownloadLabelText = styled(Text)`
    ${mediaQueries.tabletLandscape} {
        width: 100%;
    }
`;

export const QRWrapper = styled.div`
    position: absolute;
    top: 140px;
`;

export const FailedContainer = styled.div`
    width: 343px;

    margin: 101px auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const FailedIcon = styled(Image)`
    width: 40px;
    margin: 24px auto;
`;
