import { Trans, useTranslation } from 'react-i18next';
import React from 'react';

import { Box, Text } from 'components/library';
import ContactUsLink from 'components/ContactUsLink/ContactUsLink';

import * as S from './styled';

import ErrorIcon from './img/error.png';

export const ErrorInstallAppPage = () => {
    const { t } = useTranslation();

    return (
        <S.FailedContainer>
            <S.FailedIcon src={ErrorIcon} alt="error icon" />
            <Text type="h5" mb={12} center>
                <Trans i18nKey="installAppError.title" />
            </Text>
            <Text mb={32} type="medium-text" color="text-secondary" center>
                <Trans i18nKey="installAppError.subTitle" components={[<u key="0" />]} />
            </Text>
            <Box paddingBottom={32}>
                <ContactUsLink variant="button" text={t('basics.contactSupport')} />
            </Box>
        </S.FailedContainer>
    );
};
