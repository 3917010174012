import { useTranslation } from 'react-i18next';
import React from 'react';

import { SUBSCRIPTION_ACTIVE } from 'constants/payments';

import { isEmpty } from 'helpers/dataStructuresUtils';

import OutlinedButton from 'components/OutlinedButton';
import { Box, Text } from 'components/library';

import * as S from './styled';

import { IDefaultSubscription } from '../types';

import UpcomingProduct from './UpcomingProduct';
import SubscriptionCardWrapper from './SubscriptionCardWrapper';
import StatusIcon from './StatusIcon';
import SubscriptionCardHeader from './SabscriptionCardHeader';
import PriceRow from './PriceRow';

const DefaultSubscription = ({
    tabIcon,
    heading,
    state,
    subscriptionPeriod,
    price,
    expirationDate,
    onCancelSubscription,
    upcomingProduct,
    subscriptionType,
}: IDefaultSubscription) => {
    const { t } = useTranslation();

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader icon={tabIcon} heading={heading} />
            <S.GeneralInfo mb={0}>
                <S.Status>
                    <StatusIcon isActive={state === SUBSCRIPTION_ACTIVE} />
                    <Text type="caption" color="text-secondary" text={t(`subscription.status.${state}`)} />
                    <Text type="caption" color="text-secondary" text=":" />
                </S.Status>
                <PriceRow price={price} period={subscriptionPeriod} subscriptionType={subscriptionType} />
                <S.DateInfo>
                    <Text type="caption" color="text-secondary-subdued" text={t('subscription.text.expires')} />
                    <Text type="caption" color="text-secondary-subdued" text={expirationDate} />
                </S.DateInfo>
            </S.GeneralInfo>
            {upcomingProduct && !isEmpty(upcomingProduct) && <UpcomingProduct upcomingProduct={upcomingProduct} />}
            <Box mb={16} />
            <OutlinedButton
                data-locator="cancelSubscriptionButton"
                onClick={onCancelSubscription}
                text={t('subscription.text.cancel')}
            />
        </SubscriptionCardWrapper>
    );
};

export default DefaultSubscription;
